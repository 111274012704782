// 设置 rem 函数
function setRem () {
  let width = document.documentElement.clientWidth;
  const newFont = (width / 320) * 10;
  document.documentElement.style.fontSize = newFont + 'px';
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem();
};
