














import { Component, Vue } from 'vue-property-decorator';
import '../../rem';

@Component({
  components: {
    InsHeader: () => import('@/components/business/mobile/header/InsHeader.vue'),
    InsFooter: () => import('@/components/business/mobile/footer/InsFooter.vue'),
    InsSlideMenu: () => import('@/components/business/mobile/header/InsSlideMenu.vue'),
    InsMenuLayout: () => import('@/components/cmxBusiness/mobile/header/InsMenuLayout.vue'),
    InsSidebar: () => import('@/components/business/pc/header/InsSidebar.vue')
  }
})
export default class mobileIndex extends Vue {
  bigLogo: boolean = true;
  created () {
    Vue.prototype.vw = window.innerWidth;
    this.changLange(this.$Storage.get('locale') || 'E');
  }
  mounted () {
  }
  private changLange (lang) {
    this.$Api.member.setUILanguage(lang).then((result) => {
      this.$i18n.locale = lang;
      this.$Storage.set('locale', lang);
    }).catch((error) => {
      console.log(error);
    });
  }

  fixedTop (val) {
    this.bigLogo = val;
  }
}
